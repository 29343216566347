<template>
	<div class="page" v-loading="loading" element-loading-text="正在打印" element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.5)" customClass="myloading">
		<div class="container">

			<div class="con">



				<div class="box1">
					<div class="select" style='margin-bottom: 20px;'>
						<span style='font-weight: bold;'>打印状态：</span>
						<el-select v-model="query.is_print" placeholder="请选择" @change="change">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>

					<el-table :data="list" style="width: 100%">
						<el-table-column prop="create_time" label="日期" show-overflow-tooltip width="100" align="center">
						</el-table-column>
						<el-table-column prop="task_number" show-overflow-tooltip label="编号" width="100" align="center">
						</el-table-column>
						<el-table-column prop="print_number" label="打印份数(份)" width="120" align="center">
						</el-table-column>
						<el-table-column prop="money" label="打印价格(元)" width="120" align="center">
						</el-table-column>
						<el-table-column prop="type" label="类型" align="center" width="250">
							<template slot-scope="scope" align="left">

								<el-tag type="success" size="mini" v-if="scope.row.task_type == 1">文档打印</el-tag>
								<el-tag type="success" size="mini" v-if="scope.row.task_type == 2">照片打印</el-tag>
								<el-tag type="success" size="mini" v-if="scope.row.task_type == 3">证件照打印</el-tag>
								<el-tag type="success" size="mini" v-if="scope.row.task_type == 4">发票打印</el-tag>
								<el-tag type="success" size="mini" v-if="scope.row.task_type == 5">身份证打印</el-tag>
								<el-tag type="success" size="mini" v-if="scope.row.task_type == 6">复印</el-tag>
								<el-tag type="success" size="mini" v-if="scope.row.task_type == 7">扫描</el-tag>

								<!-- &nbsp; -->

								<el-tag type="success" size="mini" v-if="scope.row.print_type == 1">A3</el-tag>
								<el-tag type="success" size="mini" v-if="scope.row.print_type == 2">A4</el-tag>
								<el-tag type="success" size="mini" v-if="scope.row.print_type == 3">一寸</el-tag>
								<el-tag type="success" size="mini" v-if="scope.row.print_type == 4">两寸</el-tag>
								<el-tag type="success" size="mini" v-if="scope.row.print_type == 5">大一寸</el-tag>
								<el-tag type="success" size="mini" v-if="scope.row.print_type == 6">大两寸</el-tag>
								<el-tag type="success" size="mini" v-if="scope.row.print_type == 7">六寸</el-tag>

								<!-- &nbsp; -->

								<el-tag type="success" size="mini" v-if="scope.row.print_ctype == 1">单面</el-tag>
								<el-tag type="success" size="mini" v-if="scope.row.print_ctype == 2">双面</el-tag>

								<!-- 	&nbsp; -->

								<el-tag type="success" size="mini" v-if="scope.row.paper_type == 1">黑白</el-tag>
								<el-tag type="success" size="mini" v-if="scope.row.paper_type == 3">黑白去底色</el-tag>
								<el-tag type="success" size="mini" v-if="scope.row.paper_type == 2">彩色</el-tag>

								<!-- 	&nbsp; -->

								<el-tag type="success" size="mini" v-if="scope.row.print_typeset == 1">竖版</el-tag>
								<el-tag type="success" size="mini" v-if="scope.row.print_typeset == 2">横版</el-tag>

							</template>
						</el-table-column>


						<el-table-column prop="print_type" label="预览" align="center" width="100">
							<template slot-scope="scope">


								<el-image
									v-if="scope.row.task_type !=1 && scope.row.task_type !=7 && scope.row.task_type !=6 &&scope.row.order_type != 2"
									style="width: 50px; height: 50px" :src="$domain+scope.row.print_file"
									:preview-src-list="srcList">
								</el-image>

								<template v-else>

										<el-button type="primary" @click="previewfile(scope.row)" size="small">预览</el-button>
									

								</template>



							</template>
						</el-table-column>


						<el-table-column prop="print_type" label="打印状态" align="center" width="100">
							<template slot-scope="scope" v-if="scope.row.task_type != 7">
								<!-- 	{{ scope.row.print_ctype }} -->
								<el-tag v-if="scope.row.is_print == 1">未打印</el-tag>
								<el-tag type="success" v-if="scope.row.is_print == 2">已打印</el-tag>

							</template>
						</el-table-column>


						<el-table-column prop="order_status" label="支付状态" align="center" width="100">
							<template slot-scope="scope">
								<el-tag v-if="scope.row.order_status == 0">待支付</el-tag>
								<el-tag type="success" v-if="scope.row.order_status == 1">已支付</el-tag>
								<el-tag type="success" v-if="scope.row.order_status == 2">待退款</el-tag>
								<el-tag type="success" v-if="scope.row.order_status == 3">已退款</el-tag>
							</template>
						</el-table-column>

						<el-table-column label="操作">
							<template slot-scope="scope">

								<div class="handerbtns" v-if="scope.row.task_type != 7">



									<el-button size="mini" style="background-color: #409EFF; color:#fff;"
										@click.once="print(scope.row,2)"
										v-if="scope.row.order_status == 1  && scope.row.is_print == 1">打印</el-button>



									<el-button size="mini" style="background-color: #E6A23C; color:#fff;"
										v-if="scope.row.order_status == 0" @click="payselect(scope.row)">付款</el-button>


									<el-button size="mini" @click="repeat(scope.row)" v-if="scope.row.is_print == 2"
										style="background-color: #5CDBD3; color:#fff; ">
										再次打印</el-button>

									<el-button size="mini" @click="orderDel(scope.row)"
										style="background-color: #F56C6C; color:#fff; ">
										删除任务</el-button>



								</div>

								<div v-else style="text-align: center;">
									<el-button size="mini"
										style="background-color: #409EFF; color:#fff; margin: 0 auto;"
										@click="showPdfCode(scope.row)" v-if="scope.row.order_status == 1">扫码下载
									</el-button>

									<el-button size="mini" style="background-color: #E6A23C; color:#fff;"
										v-if="scope.row.order_status == 0" @click="payselect(scope.row)">付款</el-button>


									<el-button size="mini" @click="orderDel(scope.row)"
										style="background-color: #F56C6C; color:#fff; ">
										删除任务</el-button>

								</div>





							</template>
						</el-table-column>
					</el-table>


					<div class="page">
						<el-pagination @current-change="handleCurrentChange" :current-page.sync="query.page"
							:page-size="query.limit" layout="total, prev, pager, next" :total="total">
						</el-pagination>
					</div>


					<el-dialog :visible.sync="dialogVisible" width="20%">
						<div style="text-align: center; margin-bottom: 50px; font-weight: bold;">请选择支付方式</div>
						<div class="btns" style="display:flex; justify-content: space-between;">
							<el-button type="success" @click="pay(4)">微信支付</el-button>
							<el-button type="success" @click="pay(2)">余额支付</el-button>
							<el-button type="success" @click="pay(3)">企业支付</el-button>
						</div>

					</el-dialog>



					<el-dialog :visible.sync="dialogPdfCode" width="20%" style="text-align: center;">
						<p>请使用微信扫码下载PDF文件</p>
						<vue-qr :text="pdfCode" :size="200"
							style="margin:0 auto; width:200px; height:200px; margin-top:30px" v-if="pdfCode"></vue-qr>
						<p>
							<el-button type="primary" style="width:80%" @click="dialogPdfCode = false">关闭</el-button>
						</p>

					</el-dialog>




					<div ref="box1" class="photobox" style="">
						<div class="photo" style="width:20.4cm; height:30.4cm; display: block;">
							<img class='pic1' :src="pic" alt=""
								style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
							<img class='pic1' :src="pic" alt=""
								style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
							<img class='pic1' :src="pic" alt=""
								style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
							<img class='pic1' :src="pic" alt=""
								style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
							<img class='pic1' :src="pic" alt=""
								style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
							<img class='pic1' :src="pic" alt=""
								style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
							<img class='pic1' :src="pic" alt=""
								style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
							<img class='pic1' :src="pic" alt=""
								style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
							<img class='pic1' :src="pic" alt=""
								style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
							<img class='pic1' :src="pic" alt=""
								style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
							<img class='pic1' :src="pic" alt=""
								style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
							<img class='pic1' :src="pic" alt=""
								style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
							<img class='pic1' :src="pic" alt=""
								style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
							<img class='pic1' :src="pic" alt=""
								style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
							<img class='pic1' :src="pic" alt=""
								style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
							<img class='pic1' :src="pic" alt=""
								style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
						</div>
					</div>


					<div ref="box2" class="photobox" style="">
						<div class="photo" style="width:20.4cm; height:30.4cm;   text-align: center;   ">
							<div class="box" style="width:18cm; margin:0 auto">

								<img class='pic1' :src="pic" alt=""
									style="float:left;width:7cm; height:9.8cm; margin:1cm">
								<img class='pic1' :src="pic" alt=""
									style="float:left;width:7cm; height:9.8cm; margin:1cm">
								<img class='pic1' :src="pic" alt=""
									style="float:left;width:7cm; height:9.8cm; margin:1cm">
								<img class='pic1' :src="pic" alt=""
									style="float:left;width:7cm; height:9.8cm; margin:1cm">



							</div>
						</div>
					</div>



					<div ref="box3" class="photobox" style="">
						<div class="photo" style="width:20.4cm; height:30.4cm;   text-align: center;   ">
							<div class="box" style="margin:0 auto">
								<img class='pic1' :src="pic" alt=""
									style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
								<img class='pic1' :src="pic" alt=""
									style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
								<img class='pic1' :src="pic" alt=""
									style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
								<img class='pic1' :src="pic" alt=""
									style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
								<img class='pic1' :src="pic" alt=""
									style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
								<img class='pic1' :src="pic" alt=""
									style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
								<img class='pic1' :src="pic" alt=""
									style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
								<img class='pic1' :src="pic" alt=""
									style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
								<img class='pic1' :src="pic" alt=""
									style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
							</div>
						</div>
					</div>



					<div ref="box4" class="photobox" style="">
						<div class="photo" style="width:20.4cm; height:30.4cm;  text-align: center;  ">
							<div class="box" style="width:18cm; margin:0 auto">
								<img class='pic1' :src="pic" alt=""
									style="float:left;width:7cm; height:10.6cm; margin:1cm">
								<img class='pic1' :src="pic" alt=""
									style="float:left;width:7cm; height:10.6cm; margin:1cm">
								<img class='pic1' :src="pic" alt=""
									style="float:left;width:7cm; height:10.6cm; margin:1cm">
								<img class='pic1' :src="pic" alt=""
									style="float:left;width:7cm; height:10.6cm; margin:1cm">
							</div>
						</div>
					</div>



					<div ref="box5" class="photobox">
						<div class="photo" style="width:20.4cm; height:30.4cm;   text-align: center;">
							<img class='pic1' :src="pic" alt="" style="width:100%; height:100%">
						</div>
					</div>


					<el-dialog title="微信扫码支付" :visible.sync="dialogVisible2" width="20%" :before-close="handleClose2"
						style="text-align: center;">

						<vue-qr :text="code" :size="200" style="margin:0 auto"></vue-qr>

					</el-dialog>


					<el-dialog :visible.sync="printOk" width="30%">
						<div class="info" style="text-align: center;">
							<p class='iconfont icon-chenggong' style="font-size: 100px; color:#67C23A"></p>
							<p style="color:#67C23A; font-size: 50px; line-height: 100px;">打印成功</p>

							<div v-if="$store.state.userinfo">
								<p style="color:red; font-weight: bold; font-size: 16px; margin-bottom: 20px;">
									为确保您的信息安全，打印完成后记得退出</p>
								<p>
									<el-button type="danger" style="width:80%" @click="loginout()">退出</el-button>
								</p>
								<p>
									<el-button type="primary" style="width:80%; margin:30px 0" @click="printOk=false">
										继续打印
									</el-button>
								</p>
							</div>
						</div>
					</el-dialog>






				</div>

			</div>
		</div>
		
		
		
		<Print ref="print"></Print>
		
		
		
	</div>


</template>

<script>
	import vueQr from 'vue-qr'
	import Print from "@/components/Print.vue"
	import {
		getLodop
	} from '../../utils/LodopFuncs'
	export default {
		name: 'Home',
		components: {
			vueQr,
			Print
		},

		data() {
			return {
				paytype: false,
				newOrder: '',
				code: require("@/assets/images/code.png"),
				dialogVisible: false,
				dialogVisible2: false,
				dialogPdfCode: false,
				pdfCode: '',
				loading: false,
				list: [],
				total: 0,
				photo: '',
				curObj: null,
				code: '',
				timer: null,
				pic: '',
				srcList: [],


				timer: null,
				jobCode: '1', //job码
				printStatus: 1, //打印状态
				loading: false,
				printOk: false,
				currObj: null,
				query: {
					page: 1,
					limit: 5,
					is_print: 0
				},
				options: [{
					value: 0,
					label: '全部',
				}, {
					value: 1,
					label: '未打印',
				}, {
					value: 2,
					label: '已打印',
				}],
			}
		},
		created() {
			this.TaskList(); //任务列表
		},
		methods: {

			//删除任务
			async orderDel(obj) {
				let that = this

				this.$confirm('此操作将永久删除该任务, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {


					let res = await this.$api.orderDel({
						order_id: obj.order_id
					});
					if (res.code == 200) {
						this.$message.success('文件删除成功')
						this.TaskList()
					}



				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});

			},



			//下载弹窗
			showPdfCode(obj) {
				console.log('---', obj)
				this.pdfCode = this.$domain + '/' + obj.print_file;
				this.dialogPdfCode = true;
			},



			//再次打印 创建订单
			async repeat(obj) {
				//console.log('再次打印',id)
				let res = await this.$api.again_print({
					'id': obj.id
				});
				console.log('再次打印', res);
				if (res.code == 200) {
					this.TaskList()

					this.newOrder = obj;
					this.newOrder.order_number = res.order_number;
					this.newOrder.task_number = res.task_number;
					this.newOrder.id = res.id;

					this.currObj = this.newOrder;
					this.dialogVisible = true;

				}


			},
			//订单支付
			async orderPay() {
				let data = {
					id: this.newOrder.id,
					type: 2
				}
				let res = await this.$api.orderPay(data);
				this.paytype = false;

				console.log('555555555555',this.newOrder)
				if (this.newOrder.task_type == 7) { //扫描的无需打印

					return false;
				}

				return false;

				this.print(this.newOrder, 2)

			},






			async OrderDetail(data) {

				try {
					let res = await this.$api.OrderDetail(data);
					if (res.code == 200) {
						console.log('订单详情222', res);


						let hb = res.data.print_file.split('.');
						hb = hb[0] + '_hb.' + hb[1];


						res.data.print_hb_file = hb;
						console.log('hb', hb)

						if (res.data.order_status == '1') {

							if (res.data.task_type == '3' || res.data.task_type == '5') { //照片打印
								this.pic = res.data.print_file;
								console.log('照片准备打印')
								this.print(res.data);
							}

							if (res.data.task_type == '1') { //文档打印
								this.pic = res.data.print_file;


								console.log('文档准备打印', res.data)
								this.printFile(res.data);
							}

						}

					}


				} catch (err) {
					this.$message.error('数据请求失败');
				}
			},


			async TaskList() {
				try {
					let res = await this.$api.TaskList(this.query);
					if (res.code == 200) {
						console.log('aa', res.data.rows)
						this.list = res.data.rows;
						this.list.map((item) => {
							if (item.task_type == 2 || item.task_type == 3 || item.task_type == 5) {
								this.srcList.push(`${this.$domain+item.print_file}`)
							}

						})

						this.total = res.data.page_info.total;
					}
				} catch (err) {
					this.$message.error('数据请求失败');
				}
			},

			payselect(obj) {
				this.currObj = obj;
				this.dialogVisible = true;
			},

			handleClose2() {
				clearTimeout(this.timer)
				this.dialogVisible2 = false;
			},


			async pay(type) {

	
				let data = {
					id: this.currObj.id,
					type
				};

				try {
					let res = await this.$api.orderPay(data);
					
					if (type == 2 || type == 3) { //余额支付
						if (res.code == 200) {
							this.$message.success(res.msg)
							
							console.log('余额支付',this.currObj);
							if(this.currObj.task_type == '7'){
							    console.log('重新请求');
								this.TaskList();
								this.dialogVisible = false;
								return false;
							}

							this.print(this.currObj, 2)

						} else {
							this.$message.error(res.msg)
						}
					}

					if (type == 4) { //微信支付
						console.log(res)
						this.code = res.data.code_url;
						this.dialogVisible2 = true;

						this.timer = setInterval(() => {
							this.scanPaySuccessful(res.data.order_number);
						}, 1000)

					}

					this.dialogVisible = false;


				} catch (err) {
					console.log(err)
					this.$message.error('数据请求失败4444444', err);
					this.dialogVisible = false;
				}


			},


			async scanPaySuccessful(order) {
				let res = await this.$api.scanPaySuccessful({
					'order_number': order
				});
				console.log(res);
				if (res.data == "SUCCESS") {
					clearTimeout(this.timer);
					this.dialogVisible2 = false;
					this.dialogVisible = false;
					this.$message.success('微信支付成功')
					clearTimeout(this.timer)
					
					if(this.currObj.task_type == '7'){  //扫码无需打印
					    
						this.TaskList();
						return false;
					}
					
					this.print(this.currObj, 2)

					
				}
			},




			handleCurrentChange(e) {
				console.log(e)
				this.query.page = e;
				this.TaskList()
			},


			change() {
				this.query.page = 1;
				this.query.is_print = this.query.is_print;
				this.TaskList()
				console.log('aa', this.query.is_print)
			},

			print(obj, type) {
				//  obj.print_file = this.$domain + obj.print_file


				let hb = obj.print_file.split('.');
				hb = hb[0] + '_hb.' + hb[1];


				obj.print_hb_file = hb;



				/* if (obj.task_type == 2 || obj.task_type == 3 || obj.task_type == 5) { //照片 证件照   和身份证

					this.photoPrint(obj, type);

				} else {
					this.filePrint(obj, type);
				}
 */

               this.$refs.print.order(obj, 'printfile')

			},
			
			previewfile(obj){
				
				
				console.log('obj',obj)
				let hb = obj.print_file.split('.');
				hb = hb[0] + '_hb.' + hb[1];
				
				
				obj.print_hb_file = hb;
				this.$refs.print.order(obj, 'previewfile')
				
				setTimeout(()=>{
					this.TaskList()
				},10)
				
			},



			getStatusValue(job) { //根据job代码，获取是否打印成功

				var self = this;
				LODOP = getLodop();
				LODOP.On_Return = function(TaskID, Value) {
					console.log("TaskID:" + TaskID);
					console.log("打印成功状态:" + Value); //打印成功状态
					self.printStatus = Value;
				};
				LODOP.GET_VALUE("PRINT_STATUS_EXIST", job);
			},





			//照片打印
			photoPrint(obj, type) {
				console.log('打印参数----88', obj)
				let self = this
				if (obj.task_type == 5 || obj.task_type == 8) { //身份证直接用图片打印
					let LODOP = getLodop();

					if (obj.paper_type == 1) {
						obj.print_file = obj.print_hb_file
					}




					this.loading = true;


					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 1); //单面打印

					if (localStorage.getItem('model') == '1') {
						LODOP.SET_PRINTER_INDEXA(this.$store.state.devList['EPSON WF-C5290 Series']);
						
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4 210 x 297 毫米');
						
						
						
					} else {
						LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['iR-ADV C5235']);
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4');
					}




					/* 	 LODOP.ADD_PRINT_IMAGE("10%", "15%", "100%", "100%", `<div style="width:42cm; height:59.4cm; ">
						     <img  style="width:510px; height:580px;" src=${this.$domain+obj.print_file}>
						   </div>`);
						   */

					if (obj.task_type == 8) {
						LODOP.ADD_PRINT_IMAGE("10%", "15%", "100%", "100%", `<div style="width:42cm; height:59.4cm; ">
					   	    <img  style="width:510px; height:580px;" src=${this.$domain+obj.print_file}>
					   	  </div>`);

					} else {
						LODOP.ADD_PRINT_IMAGE("10%", "25%", "100%", "100%", `<div style="width:42cm; height:59.4cm; ">
					   	    <img  style="width:500px; height:630px;" src=${this.$domain+obj.print_file}>
					   	  </div>`);
					}



					LODOP.SET_PRINT_COPIES(obj.print_number); //打印份数
					LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);







					LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);
					LODOP.On_Return = function(TaskID, Value) {
						console.log("TaskID:" + TaskID);
						console.log("Value:" + Value); //job代码
						self.jobCode = Value;
						var timer = setInterval(function() {
							console.log("每次轮询的状态：" + self.printStatus);
							console.log('轮询bb', self.loading)
							if (self.printStatus != 1) { //打印成功
								clearInterval(timer);
								self.printStatus = 1;
								self.loading = false;

								self.open();

								let data = {
									'task_number': obj.task_number,
									'device_number': localStorage.getItem('device')
								};
								self.accomplish(data);
								this.TaskList()

								return;
							}
							self.getStatusValue(Value);
						}, 1000);
					};
					LODOP.PREVIEW();;

					return false;

				}


				//图片a4纸打印
				if (obj.task_type == 2 && obj.print_type == 2) {
					let LODOP = getLodop();
					console.log('图片a4纸打印');

					if (obj.paper_type == 1) {
						obj.print_file = obj.print_hb_file
					}

					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 1); //单面打印

					if (localStorage.getItem('model') == '1') {
						LODOP.SET_PRINTER_INDEXA(this.$store.state.devList['EPSON WF-C5290 Series']);
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4 210 x 297 毫米');
					} else {
						LODOP.SET_PRINTER_INDEXA(this.$store.state.devList['iR-ADV C5235']);
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4');
					}



					//	return false;
					LODOP.ADD_PRINT_IMAGE("0", "0", "100%", "100%",
						` <img style="width:21cm; height:29.7cm" src=${this.$domain+obj.print_file}>`);


					LODOP.SET_PRINT_COPIES(obj.print_number); //打印份数
					LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);



					LODOP.On_Return = function(TaskID, Value) {
						console.log("TaskID:" + TaskID);
						console.log("Value:" + Value); //job代码
						self.jobCode = Value;
						var timer = setInterval(function() {
							console.log("每次轮询的状态：" + self.printStatus);
							if (self.printStatus != 0) { //打印成功
								clearInterval(timer);
								self.printStatus = 1;
								self.loading = false;
								self.open();

								let data = {
									'task_number': obj.task_number,
									'device_number': localStorage.getItem('device')
								};
								self.accomplish(data);

								return;
							}
							self.getStatusValue(Value);
						}, 1000);
					};
					LODOP.PREVIEW();;
					//LODOP.PREVIEW();;



					return false;
				}




				//照片打印
				var boxname = "";
				this.pic = this.$domain + obj.print_file;
				switch (obj.print_type) {
					case 3: //一寸
						boxname = 'box1'
						break;
					case 4: //两寸
						boxname = 'box2'
						break;
					case 5: //大一寸
						boxname = 'box3'
						break;
					case 6: //大两寸
						boxname = 'box4'
						break;
					case 7: //6寸
						boxname = 'box5'
						break;
				}




				this.$nextTick(() => {
					var str = this.$refs[boxname].innerHTML;
					console.log('str', str)
					console.log('pic2', this.pic)
					let LODOP = getLodop();
					LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['HiTi P525L']);
					LODOP.ADD_PRINT_IMAGE(0, 0, "100%", "100%", str);
					LODOP.SET_PRINT_STYLEA(0, "Stretch", 1);


					this.loading = true;
					console.log('打印')

					LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);
					LODOP.On_Return = function(TaskID, Value) {
						console.log("TaskID:" + TaskID);
						console.log("Value:" + Value); //job代码
						self.jobCode = Value;
						var timer = setInterval(function() {
							console.log("每次轮询的状态：" + self.printStatus);
							console.log('轮询bb', self.loading)
							if (self.printStatus != 1) { //打印成功
								clearInterval(timer);
								self.printStatus = 1;
								self.loading = false;

								self.open();

								let data = {
									'task_number': obj.task_number,
									'device_number': localStorage.getItem('device')
								};
								self.accomplish(data);
								this.TaskList()

								return;
							}
							self.getStatusValue(Value);
						}, 1000);
					};


					LODOP.PREVIEW();;
					//LODOP.PREVIEW();;


				})





			},




			open() {
				this.printOk = true;

				if (this.$store.state.userinfo) {
					//this.$router.go(0)
				} else {
					setTimeout(() => {
						this.$router.push('/RegisterVip')
					}, 3000)

				}

			},


			//文件打印
			filePrint(obj, type) {
				let LODOP = getLodop();
				console.log('打印参数-------', obj);

				if (obj.paper_type == 1) {
					obj.print_file = obj.print_hb_file;
				}

				if (obj.paper_type == 3) {
					obj.print_file = obj.print_hb_qds_file;
				}

				//	return false;
				let self = this;

				LODOP.PRINT_INIT("测试PDF打印功能");
				//	LODOP.SET_PRINTER_INDEXA(1);



				//model = 2 才能打印a3
				if (localStorage.getItem('model') == '1') {
					if (obj.print_type == 1) { //a3纸
						this.$message.error('对不起，当前设备不支持A3纸打印');
						return false;
					}

					LODOP.SET_PRINTER_INDEXA(this.$store.state.devList['EPSON WF-C5290 Series']);

					if (obj.print_typeset == '1') {
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4 210 x 297 毫米');
					} else {
						LODOP.SET_PRINT_PAGESIZE(2, 0, 0, 'A4 210 x 297 毫米');
					}




					//扫描文件统一横向处理
					if (obj.task_type == '6' || obj.print_type == '7') {

						LODOP.SET_PRINT_PAGESIZE(2, 0, 0, 'A4 210 x 297 毫米');
					}

				} else {
					LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['iR-ADV C5235']);
					LODOP.SET_PRINT_PAGESIZE(0, 0, 0, "");
					if (obj.print_type == 1) { //a3纸
						LODOP.SET_PRINT_PAGESIZE(2, 0, 0, 'A3');
						//console.log('打印A3纸张')

					} else { //a4纸张

						if (obj.print_typeset == '1') {
							LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4');
						} else {
							LODOP.SET_PRINT_PAGESIZE(2, 0, 0, 'A4');
						}


						if (obj.task_type == '6' || obj.print_type == '7') {
							LODOP.SET_PRINT_PAGESIZE(2, 0, 0, 'A4');
						}
					}


				}

				this.loading = true;





				LODOP.ADD_PRINT_PDF(0, 0, "100%", "100%", this.demoDownloadPDF(this.$domain + obj.print_file));
				if (obj.print_ctype == 2) {
					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 2);
				}

				LODOP.SET_PRINT_COPIES(obj.print_number); //打印份数

				LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);

				LODOP.On_Return = function(TaskID, Value) {
					console.log("TaskID:" + TaskID);
					console.log("Value:" + Value); //job代码
					self.jobCode = Value;
					var timer = setInterval(function() {
						console.log("每次轮询的状态：" + self.printStatus);
						if (self.printStatus != 0) { //打印成功
							clearInterval(timer);
							self.printStatus = 1;
							self.loading = false;
							self.open();

							let data = {
								'task_number': obj.task_number,
								'device_number': localStorage.getItem('device')
							};

							self.accomplish(data);
							self.TaskList()
							return;
						}
						self.getStatusValue(Value);
					}, 1000);
				};
				LODOP.PREVIEW();;
				//LODOP.PREVIEW();
			},



			async accomplish(data) {

				let res = await this.$api.accomplish(data);
				console.log('测试一下下');
				this.TaskList();
			},


			demoDownloadPDF(url) {
				if (!(/^https?:/i.test(url))) return;
				if (window.XMLHttpRequest) var xhr = new XMLHttpRequest();
				else var xhr = new ActiveXObject("MSXML2.XMLHTTP");
				xhr.open('GET', url, false); //同步方式
				if (xhr.overrideMimeType)
					try {
						xhr.responseType = 'arraybuffer';
						var arrybuffer = true;
					} catch (err) {
						xhr.overrideMimeType('text/plain; charset=x-user-defined');
					}
				xhr.send(null);
				var data = xhr.response || xhr.responseBody;
				if (typeof Uint8Array !== 'undefined') {
					if (arrybuffer) var dataArray = new Uint8Array(data);
					else {
						var dataArray = new Uint8Array(data.length);
						for (var i = 0; i < dataArray.length; i++) {
							dataArray[i] = data.charCodeAt(i);
						}
					}
				} else
					var dataArray = VBS_BinaryToArray(data).toArray(); //兼容IE低版本
				return this.demoGetBASE64(dataArray);
			},

			demoGetBASE64(dataArray) {
				var digits = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
				var strData = "";
				for (var i = 0, ii = dataArray.length; i < ii; i += 3) {
					if (isNaN(dataArray[i])) break;
					var b1 = dataArray[i] & 0xFF,
						b2 = dataArray[i + 1] & 0xFF,
						b3 = dataArray[i + 2] & 0xFF;
					var d1 = b1 >> 2,
						d2 = ((b1 & 3) << 4) | (b2 >> 4);
					var d3 = i + 1 < ii ? ((b2 & 0xF) << 2) | (b3 >> 6) : 64;
					var d4 = i + 2 < ii ? (b3 & 0x3F) : 64;
					strData += digits.substring(d1, d1 + 1) + digits.substring(d2, d2 + 1) + digits.substring(d3, d3 + 1) +
						digits.substring(d4, d4 + 1);
				}
				return strData;
			},


			go(url) {
				this.$router.push({
					path: url,
				})
			},
			back() {
				console.log(11)
				this.$router.back();
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		width: 100%;
		background-color: #fff;
		/* margin-top: 30px; */
	}

	.photobox {
		display: none;
	}

	.con {
		padding-top: 50px;
		width: 100%;
		height: 80vh;
		background: #fff;
		display: flex;
		justify-content: space-between;

		.box1 {
			flex: 5;

			.page {
				width: 600px;
				margin: 100px auto 0;

			}


		}

		.box2 {
			flex: 1;
			border-right: 1px solid #ccc;
			border-left: 1px solid #ccc;

			/*  background-color: yellow; */
			.category {
				margin-top: 15px;
				font-size: 15px;
				font-weight: bold;
				line-height: 30px;
				text-align: center;
			}

		}


	}

	.top {
		margin-top: -15px;
		height: 30px;
		color: #fff;
		background-color: #259DDC;

	}

	.left {
		float: left;
	}

	.right {
		float: right;
	}

	.handerbtns {

		.el-button {
			float: left;
			margin-left: 20px;

		}
	}
</style>
